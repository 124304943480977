import React, { useState, useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { backgroundImage1, revenuegrowth, transactionsuccess, employee} from '../../assets';

type IncrementFunction = (value: number, setValue: React.Dispatch<React.SetStateAction<number>>, target: number) => void;

const Counter: React.FC = () => {
  const targetRevenue = 500;
  const targetTransactions = 500;
  const targetExperience = 15;

  const [revenue, setRevenue] = useState(0);
  const [transactions, setTransactions] = useState(0);
  const [experience, setExperience] = useState(0);

  const incrementCounter: IncrementFunction = (value, setValue, target) => {
    if (value < target) {
      const increment = Math.ceil(target / 100);
      setValue((prev: number) => Math.min(prev + increment, target));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => incrementCounter(revenue, setRevenue, targetRevenue), 50);
    return () => clearInterval(interval);
  }, [revenue]);

  useEffect(() => {
    const interval = setInterval(() => incrementCounter(transactions, setTransactions, targetTransactions), 50);
    return () => clearInterval(interval);
  }, [transactions]);

  useEffect(() => {
    const interval = setInterval(() => incrementCounter(experience, setExperience, targetExperience), 50);
    return () => clearInterval(interval);
  }, [experience]);

  return (
    <Box
      sx={{
        height: 'auto',
        position: 'relative',
        overflow: 'hidden',
        backgroundImage: `url(${backgroundImage1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        color: '#fff',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(48, 119, 157, 0.6)', // Darken overlay
          zIndex: 0,
        },
        py: 4,
      }}
      mt={8}
      mb={8}
    >
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 4 }}>
          {/* Revenue Counter */}
          <Box textAlign="center" sx={{ position: 'relative', zIndex: 1 }}>
            <img src={revenuegrowth} alt="Revenue Icon" style={{ width: '50px', marginBottom: '10px' }} />
            <Typography
              variant="h4"
              sx={{
                color: '#ffffff',
                fontWeight: '700',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Add shadow for contrast
              }}
            >
              {revenue}+
            </Typography>
            <Typography variant="body1" sx={{ color: 'white', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)' }}>
              Revenue Generator
            </Typography>
          </Box>

          {/* Transactions Counter */}
          <Box textAlign="center" sx={{ position: 'relative', zIndex: 1 }}>
            <img src={transactionsuccess} alt="Transactions Icon" style={{ width: '50px', marginBottom: '10px' }} />
            <Typography
              variant="h4"
              sx={{
                color: '#ffffff',
                fontWeight: '700',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              <span style={{ fontWeight: 'bold', color: '#ffffff' }}>{transactions}+</span> 
              <span style={{ fontSize: '16px', color: '#ffffff' }}>Sales</span>
            </Typography>
            <Typography variant="body1" sx={{ color: 'white', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)' }}>
              No. of Successful Transactions
            </Typography>
          </Box>

          {/* Experience Counter */}
          <Box textAlign="center" sx={{ position: 'relative', zIndex: 1 }}>
            <img src={employee} alt="Experience Icon" style={{ width: '50px', marginBottom: '10px' }} />
            <Typography
              variant="h4"
              sx={{
                color: '#ffffff',
                fontWeight: '700',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              {experience}+
            </Typography>
            <Typography variant="body1" sx={{ color: 'white', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)' }}>
              Years Of Experience
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Counter;
