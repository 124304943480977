import React from 'react';
import { Box, Typography, Grid, Card, Button, Container } from '@mui/material';
import { ServiceImages } from '../../assets';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const totaldata = [
  {
    title: "Custom Designed Sales Mechanisms",
    description: "Establishing an Efficient Tailormade Sales Mechanism to Suit each Project based on the Product & Location",
    logo: ServiceImages.service1,
  },
  {
    title: "Brand Consulting & Enhanced Customer Experience",
    description: "Boosting confidence in customers and teams with friendly policies and incentives for loyalty, performance, and exceptional customer experience through HR",
    logo: ServiceImages.service2,
  },
  {
    title: "Tech Tools & Automation Systems Implementation",
    description: "Identify & Match the Project requirement with the Best suited HRM & CRM Tools for Reporting & Tracking & to ensure enforceability across the organization",
    logo: ServiceImages.service3,
  },
  {
    title: "Vendor Management Services",
    description: "Engage vendors in various spheres in the organization wrt Sales, Marketing & Business Development",
    logo: ServiceImages.service4,
  },
  {
    title: "Networking & Visibility Enhancement",
    description: "Align Channel Partner network to the organization to increase word of mouth marketing/ branding & Sales Mobilisation",
    logo: ServiceImages.service5,
  },
  {
    title: "Tracking & Reporting Systems",
    description: "Drive the teams & track workforce performance weekly/monthly to make sure the set processes are being followed in order to achieve the desired output",
    logo: ServiceImages.service6,
  },
  {
    title: "Marketing Campaigns & Strategies",
    description: "To prepare a Marketing Calendar & Run regular campaigns both ATL & BTL activities",
    logo: ServiceImages.service7,
  },
  {
    title: "Strategic Alliances & Associations",
    description: "Creating strategic alliances & establishing tie-ups with the organization with Bankers, Investors, Marketers, Vendors & Associations that help in quick turn around",
    logo: ServiceImages.service8,
  },
  {
    title: "Management Consulting Services",
    description: "Optimizing practices for organizational growth, refining functions, and implementing end-to-end improvements for operational excellence across the organization",
    logo: ServiceImages.service9,
  },
];

const Services = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Container>
        <Typography
            variant="h4"
            
            sx={{ mb: 2, color: '#333', fontSize: { xs: '1.5rem', md: '2rem' }, mt: 6, fontWeight:'500' }}
         >
            Our Services
        </Typography>
        <Typography variant='body2' mt={2} mb={2} sx={{textAlign:'justify'}}>
             Our approach focuses on creating custom-tailored sales mechanisms to align with each project's specific product and location needs. We enhance customer experience by implementing brand consulting, offering friendly policies, and incentivizing loyalty and performance. By identifying and matching the right HRM and CRM tools, we ensure robust reporting, tracking, and enforceability across the organization. Vendor management is a key element, with active engagement in sales, marketing, and business development. We also run strategic marketing campaigns through both ATL and BTL activities, ensuring continuous monitoring of workforce performance. Establishing strategic alliances with bankers, investors, marketers, and vendors supports rapid growth. Through management consulting, we optimize organizational practices and implement improvements to drive operational excellence and foster long-term success.
        </Typography>
        <Grid container spacing={2}>
            {totaldata.map((data, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}> {/* 3 items per row on md (medium) screens */}
                <Card
                sx={{
                    m: 2,
                    textAlign: 'left',
                    bgcolor: '#E6F0F3',
                    padding: { xs: 2, md: 4 },
                    borderRadius: 3,
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: { xs: 'auto', md: '400px' },
                    '&:hover': {
                    bgcolor: '#30779d',
                    '& .MuiTypography-root': { color: 'white' },
                    },
                }}
                >
                <Box
                    component="img"
                    src={data.logo}
                    alt={`${data.title} logo`}
                    sx={{ width: { xs: '50px', md: '80px' }, mb: 1 }}
                />
                <Typography
                    variant="h6"
                    sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '1rem', md: '1.25rem' },
                    color: 'black',
                    mb: 0.5,
                    }}
                >
                    {data.title}
                </Typography>
                <Typography
                    variant="caption"
                    sx={{
                    fontSize: { xs: '0.75rem', md: '0.875rem' },
                    color: 'black',
                    mb: 2,
                    }}
                >
                    {data.description}
                </Typography>
                <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                    alignSelf: 'flex-start', // Align button to the left
                    bgcolor: '#30779d',
                    color: 'white',
                    '&:hover': {
                        bgcolor: '#205973',
                    },
                    }}
                >
                    Read More
                </Button>
                </Card>
            </Grid>
            ))}
        </Grid>
        </Container>
    </Box>
  );
};

export default Services;
