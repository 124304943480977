import React from 'react';
import { Grid, Paper, Box, Container, Typography } from '@mui/material';
import { mission, vision, mission1 } from '../../assets';

const Vision: React.FC = () => {
  return (
    <Box sx={{ background: 'white', padding: { xs: '10px 0', md: '20px 0' } }}>
      <Container>
        <Box sx={{ width: '100%', padding: { xs: '10px 0', md: '0px 0' } }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2,  }}>
            
            {/* Text Section */}
            <Grid 
              item 
              xs={12} 
              md={7} 
              sx={{ 
                textAlign: { xs: 'center', md: 'start' }, 
                display: 'flex', 
                justifyContent: 'center', 
                flexDirection: 'column' 
              }}
            >
              <Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    textAlign: { xs: 'center', md: 'start' }, 
                    fontWeight: '600', 
                    mb: 2,
                    fontSize: { xs: '0.875rem', md: '1.2rem' } 
                  }}
                >
                  Our Mission
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                paragraph 
                sx={{ 
                  textAlign: 'justify', 
                  fontSize: { xs: '0.875rem', md: '1rem' } 
                }}
              >
                We create seamless experiences that drive growth and innovation in the real estate sector. Our mission is to provide cutting-edge solutions that inspire trust and foster lasting partnerships. We are committed to making every real estate journey efficient, rewarding, and impactful for all involved, ensuring excellence in every project we undertake.
              </Typography>
            </Grid>
            
            {/* Image Section */}
            <Grid item xs={12} md={5}>
              <Box sx={{ height: '100%', width: '100%' }}>
                <img 
                  src={vision} 
                  alt="Our Vision"  
                  style={{ 
                    borderRadius: '20px 0 20px 20px', 
                    clipPath: 'polygon(0 0, 90% 0, 100% 10%, 100% 100%, 0 100%)', 
                    height: 'auto', 
                    width: '100%', 
                    maxHeight: '330px' 
                  }}
                />
              </Box>
            </Grid>

            {/* Second Image Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ height: '100%', width: '100%', mt: { xs: 4, } }}>
                <img 
                  src={mission1} 
                  alt="Our Mission"  
                  style={{ 
                    borderRadius: '20px 0 20px 20px', 
                    clipPath: 'polygon(0 0, 90% 0, 100% 10%, 100% 100%, 0 100%)', 
                    height: 'auto', 
                    width: '100%', 
                    maxHeight: '350px' 
                  }}
                />
              </Box>
            </Grid>
            
            {/* Philosophy and Approach Section */}
            <Grid 
              item 
              xs={12} 
              md={6} 
              sx={{ 
                textAlign: { xs: 'center', md: 'start' }, 
                display: 'flex', 
                justifyContent: 'center', 
                flexDirection: 'column' 
              }}
            >
              <Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    textAlign: { xs: 'center', md: 'start' }, 
                    fontWeight: '600', 
                    mb: 2,
                    fontSize: { xs: '0.875rem', md: '1.2rem' } 
                  }}
                >
                  Our Vision
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                paragraph 
                sx={{ 
                  textAlign: 'justify', 
                  fontSize: { xs: '0.875rem', md: '1rem' } 
                }}
              >
                To be a leader in transforming urban landscapes, offering unparalleled value and service to our clients. We envision a future where we create thriving communities, where every investment supports a prosperous, sustainable, and connected way of life.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Vision;
