import React, { useEffect, useState, useRef } from 'react';
import { Box, IconButton, CircularProgress, useMediaQuery, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { TbMailShare } from 'react-icons/tb';
import gsap from 'gsap';

import './Style.css';
import BuilderForm from '../components/ModalForm/BuilderForm';
import ChannelPartnerForm from '../components/ModalForm/ChannelPartnerForm';
import HomeSeekarForm from '../components/ModalForm/HomeSeekarForm';
import { Dialog } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InternalDirections from '../components/Directions/InternalDirections';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false); // For toggling form visibility
  const [formType, setFormType] = useState(''); // To track which form to show
  const [hasShownPopup, setHasShownPopup] = useState(false); // Track if popup has been shown
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    message: '',
    iAgree: false,
    homeLoan: false,
  });

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const mailIconRef = useRef(null);

  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');

  // Handle input changes for form data
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Handle form submission
  const handleSubmitForm = () => {
    console.log('Form submitted:', formData);
    setIsFormVisible(false);
    // Reset form data after submission
    setFormData({
      name: '',
      phoneNumber: '',
      email: '',
      message: '',
      iAgree: false,
      homeLoan: false,
    });
  };

  // Handle closing the form
  const handleCloseForm = () => {
    setIsFormVisible(false);
  };

  // Show the form based on button click
  const handleButtonClick = (formType: string) => {
    setFormType(formType);
    setIsFormVisible(true); // Show the form when the button is clicked
  };

 

  // Handle scroll progress (Optional, if you still need scroll progress indicator)
  const updateScrollProgress = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = (scrollTop / docHeight) * 100;
    setScrollProgress(scrolled);
    setIsVisible(scrollTop > 100);
    setIsMenuVisible(scrollTop > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollProgress);
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);

  useEffect(() => {
    if (isVisible && mailIconRef.current) {
      gsap.fromTo(mailIconRef.current, 
        { scale: 0.8 },  
        { 
          scale: 1.2,   
          duration: 0.5, 
          ease: "bounce.out",  
          repeat: -1,  
          yoyo: true, 
        });
    }
  }, [isVisible]);

  return (
    <Box>
      {/* Floating mail share icon */}
      <IconButton
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: '#FFF',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          animation: 'pulse1 1s infinite',
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            bottom: 320,
            right: 0,
            zIndex: 1000,
            borderRadius: '50% 0 0 50%',
            width: isSmallScreen ? 76 : 50,
            height: isSmallScreen ? 38 : 50,
            display: isVisible ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateX(-10px)' : 'translateX(50px)',
            transition: 'opacity 0.4s ease, transform 0.4s ease',
            color: '#757575',
            fontSize: '23px',
            backgroundColor: '#fff',
            border: '2px solid #30779d',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            animation: 'pulse1 1s infinite',
            cursor: 'pointer',
          }}
          ref={mailIconRef}
        >
          <TbMailShare onClick={() => handleButtonClick('builder')} />
        </Box>
      </IconButton>

      <Box
        sx={{
          position: 'fixed',
          bottom: -3,
          right: 36,
          zIndex: 1000,
          borderRadius: '50%',
          width: isSmallScreen ? 38 : 35,
          height: isSmallScreen ? 38 : 35,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          border: '2px solid #30779d',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(-10px)' : 'translateY(50px)',
          transition: 'opacity 0.4s ease, transform 0.4s ease',
        }}
      >
        <CircularProgress
          variant="determinate"
          value={scrollProgress}
          size={isSmallScreen ? 38 : 48}
          thickness={3}
          sx={{
            color: '#30779d',
            position: 'absolute',
          }}
        />
        <IconButton
          sx={{
            color: '#30779d',
            bgcolor: 'white',
            borderRadius: '50%',
            zIndex: 2,
            '&:hover': {
              bgcolor: 'rgba(48, 119, 157, 0.1)',
            },
          }}
        >
          <ArrowUpwardIcon fontSize={isSmallScreen ? 'small' : 'medium'} />
        </IconButton>
      </Box>

      {/* Conditionally render the form based on the formType and userType */}
      <Dialog 
        open={isFormVisible} 
        onClose={handleCloseForm}
        sx={{
          '& .MuiDialog-paper': {
            height: '66vh',  // Set the height to 66% of the viewport height
            maxHeight: '66vh', // Ensure it doesn't exceed the 66% height
          },
        }}
      >
        {userType === 'homeSeeker' ? (
          <HomeSeekarForm
            formData={formData}
            handleFormChange={handleFormChange}
            handleSubmitForm={handleSubmitForm}
            handleCloseForm={handleCloseForm}
          />
        ) : userType === 'builder' ? (
          <BuilderForm
            formData={formData}
            handleFormChange={handleFormChange}
            handleSubmitForm={handleSubmitForm}
            handleCloseForm={handleCloseForm}
          />
        ) : userType === 'channelPartner' ? (
          <ChannelPartnerForm
            formData={formData}
            handleFormChange={handleFormChange}
            handleSubmitForm={handleSubmitForm}
            handleCloseForm={handleCloseForm}
          />
        ) : null}
      </Dialog>

      {/* Optionally: Render other content like directions */}
      <Box>
        <InternalDirections />
      </Box>
    </Box>
  );
};

export default ScrollToTopButton;
