import React from 'react';
import Gallery from '../Gallery/Gallery';
// import GalleryAll from '../Gallery/GalleryAll';

const GalleryPage: React.FC = () => {
  return (
    <>
      <Gallery />
    </>
  );
};

export default GalleryPage;